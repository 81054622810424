.chat-section {
  display: inline-block;
  width: 100vw;
  position: relative;
}
.chat-section .uset-chat-header {
  padding: 20px 40px;
  width: 100%;
  display: flex;
  background-color: #fdecda;
  border-radius: 12px;
  align-items: center;
  box-sizing: border-box;
}
.uset-chat-header .user-info {
  padding-left: 15px;
  width: calc(100% - 68px);
  display: flex;
  flex-direction: column;
}
.uset-chat-header .user-info span {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
}
.uset-chat-header .user-info small {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
}
/* chat section start */
.user-chat-section,
.chat-actions {
  padding: 5px 40px;
  box-sizing: border-box;
}
/* .chat-actions{
    position:absolute;
    min-width: 65%;
    bottom: 30px;
} */
.user-chat-section .new_chat {
  position: absolute;
  min-width: 100%;
  bottom: 25px;
  /* right:25px;  */
}
.user-chat-section {
  max-height: calc(100vh - 365px);
  min-height: calc(100vh - 365px);
  overflow-y: auto;
}
.user-chat-section::-webkit-scrollbar {
  width: 2px;
}
.user-chat-section.user-list::-webkit-scrollbar-thumb {
  background-color: #333;
}
.user-chat-section .sender-msg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.sender-msg .message-wrapper,
.recevier-msg .message-wrapper {
  padding: 10px 15px;
  border-radius: 12px;
  max-width: 80%;
  min-width: 20%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 10px;
  word-break: break-word;
}
.sender-msg .message-wrapper .name,
.recevier-msg .message-wrapper .name {
  font-size: 10px;
  text-transform: capitalize;
  font-family: 500;
  margin-bottom: 5px;
  word-break: break-word;
}

.sender-msg .message-wrapper {
  background-color: #e8eaee;
  border-top-right-radius: 0;
  text-align: right;
}
.recevier-msg .message-wrapper {
  background-color: #fdecda;
  border-top-left-radius: 0;
  text-align: left;
  word-break: break-word;
}

.sender-msg .message-wrapper .name {
  color: #5c5c5c;
}
.recevier-msg .message-wrapper .name {
  color: #606060;
}

.sender-msg .message-wrapper .message,
.recevier-msg .message-wrapper .message {
  font-size: 14px;
  line-height: 16px;
  color: #111;
  white-space: wrap;
}
.sender-msg .time,
.recevier-msg .time {
  color: rgba(33, 33, 33, 1);
  font-size: 10px;
}
/* chat section end */

/* chat action start */
.chat-actions form {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.chat-actions .input-wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% - 66px);
  overflow: hidden;
}
.chat-actions .input-wrapper input {
  border: 2px solid #092e5a;
  outline: none;
  border-radius: 5px 0 0 5px;
  padding: 8px;
  height: 45px;
  box-sizing: border-box;
  border-right: none;
  width: calc(100% - 45px);
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
}
.chat-actions .input-wrapper button {
  background-color: transparent;
  border: 2px solid #092e5a;
  border-radius: 0 5px 5px 0;
  height: 45px;
  width: 45px;
  box-sizing: border-box;
  border-left: none;
  cursor: pointer;
}
.new_button {
  position: relative;
}
.attachmentFile {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  width: 100% !important;
}
.chat-actions form .send-button {
  height: 45px;
  width: 45px;
  box-sizing: border-box;
  background: #eb7a33;
  border: none;
  border-radius: 5px;
  display: grid;
  place-items: center;
  box-shadow: rgb(235 122 51 / 50%) 0px 0px 20px;
  margin-left: 20px;
  cursor: pointer;
}
.responsive_chat {
  display: none;
}
.responsive_chat_web {
  display: block;
}
.modal_view {
  height: 500px !important;
  width: 500px;
  position: relative;
}

.crossIMG{
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #16161611;
}

.modal_image {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}
/* chat sction end */

@media screen and (max-width: 767px) {
  .responsive_chat {
    display: block;
  }
  .responsive_chat_web {
    display: none;
  }
  .chat-main-container {
    top: 58px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .chat-actions {
    min-width: 100%;
    bottom: -30px;
    padding: 5px 10px;
  }
  .mobile-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
    background-color: #092e5a;
    color: #fff;
    position: fixed;
    top: 0;
    z-index: 9999;
    padding-bottom: 20px;
  }
  .mobile-header .back-button {
    border: 1px solid #fff;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 25px;
    line-height: 25px;
    font-weight: 500;
    background-color: transparent;
    border-radius: 50%;
    padding: 0;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
  .mobile-header .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
  }
  .mobile-header .user-info img {
    margin-right: 10px;
  }
  .mobile-header .user-info span {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
  }
  .titleLogo .MuiIconButton-colorInherit {
    top: 0;
  }
  .chat-section {
    max-width: 100%;
    flex-basis: 100%;
  }
  .user-chat-section {
    padding: 5px 20px;
    background-color: #fff;
    max-height: calc(100vh - 365px);
    min-height: calc(100vh - 365px);
  }
  .chat-actions {
    background-color: #fff;
  }
  .css-view-1dbjc4n {
    background-color: #092e5a;
  }
  .MuiGrid-root.main.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-space-between {
    background-color: #092e5a;
  }
  .modal_view {
    width: 100%;
    height: 500px !important;
  }
  
}
