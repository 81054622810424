.notification_modal_box .MuiOutlinedInput-input {
  padding: 12.5px 14px;
}

.MuiTypography-body1 {
  font-family: "Poppins", sans-serif !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.rootAbsolute.MuiButton-fullWidth
  .whiteimg {
  display: none;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.rootAbsolute.MuiButton-fullWidth:hover
  .whiteimg {
  display: block;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.rootAbsolute.MuiButton-fullWidth:hover
  .blackimg {
  display: none;
}

.tonav {
  position: relative;
}

.notificationContainer {
  position: fixed;
  right: 50px;
  top: 78px;
  z-index: 9999 !important;
}
.notification_box {
  max-width: 440px;
  min-width: 440px;
  max-height: 440px;
  min-height: 440px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 22px;
  border-radius: 10px;
  box-shadow: rgb(254, 184, 86, 0.5) 0px 0px 20px;
}
.admin_feed_box {
  margin-top: 10px;
  background-color: #092e5a;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif !important;
}
.admin_feed_box button {
  flex: 1;
  padding: 14px !important;
  letter-spacing: 0 !important;
  font-family: "Poppins", sans-serif !important;
}
.create_box {
  width: 100%;
  margin-top: 10px;
  background-color: #092e5a;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  text-transform: revert;
  border: none;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif !important;
}
.plus_icon {
  font-size: 30px;
  align-items: center;
}
.active_admin_feed_notify {
  background-color: #fd7e0c;
  color: #fff;
  border-radius: 8px;
  padding: 10px 10px;
  text-transform: revert;
  margin: 3px;
  border: none;
  padding: 12px 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}
.admin_feed_notify {
  background-color: #092e5a;
  color: #fff;
  border-radius: 8px;
  padding: 10px 10px;
  text-transform: revert;
  margin: 3px;
  border: none;
  padding: 12px 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}
.comment_box {
  margin-top: 20px;
  height: 270px;
  overflow-x: scroll;
  padding-right: 5px;
  -webkit-overflow-scrolling: touch;
}
.comment_box::-webkit-scrollbar {
  width: 3px;
}
.comment_box::-webkit-scrollbar-thumb {
  background-color: #dddddd;
}
.inner_comment_box {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #dfe3e7;
  border-radius: 15px;
  padding: 10px 7px 10px 0px;
  cursor: pointer;
  box-sizing: border-box;
}
.isTrue {
  background-color: #fff !important;
  border: 1px solid #dfe3e7;
}
.inner_comment_box .user_comment {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.inner_comment_box .date_data {
  margin-left: 10px !important;
}
.first_line {
  width: 4px;
  height: 35px;
  background-color: #fd7e0c;
  border-radius: 3px;
}
.user_image {
  margin-left: 5px;
}
.user_image img {
  border-radius: 50%;
  object-fit: cover;
}
.time {
  font-size: 12px;
  flex: 1;
  color: #000;
}
.date_data {
  font-size: 10px;
  flex: 1;
}
.main_notify_box {
  width: 100%;
  display: flex;
}
.slider_line_box {
  margin-left: -10px;
  padding: 30px 0px 0px 0px;
}
.top_line {
  width: 3px;
  height: 50%;
  background-color: #fd7e0c;
}
.bottom_line {
  width: 3px;
  height: 50%;
  background-color: #092e5a;
}
.notificationModal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
  z-index: 999 !important;
  /* display: none; */
}
.create-notification-wrapper {
  max-width: 600px !important;
}
.notification_modal_box {
  /* width: 100%; */
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgb(254, 184, 86, 0.5) 0px 5px 15px;
  padding: 10px 20px;
}
.admin_notification_box {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgb(254, 184, 86, 0.5) 0px 5px 15px;
  padding: 10px 20px;
}
.modal_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CN_heading {
  font-weight: 700 !important;
  font-size: 25px !important;
}
.cancelicon {
  color: #000;
}
.form_notification {
  padding: 20px 30px;
}
.Modal_btn {
  margin-top: 40px;
  width: 100%;
  background-color: #092e5a;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  text-transform: revert;
  border: none;
  padding: 12px;
  font-family: "Poppins", sans-serif !important;
}

.notificationModal_success {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}
.create-success-wrapper {
  max-width: 300px;
}
.notification_success_box {
  width: 300px;
  height: 230px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgb(254, 184, 86, 0.5) 0px 5px 15px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success_box {
  text-align: center;
  margin: auto;
}
.success_heading {
  color: #16457d;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px;
  margin-bottom: 10px;
}
.success_icon {
  font-size: 200px;
  color: #fd7e0c;
  padding: 20px 0px;
}
.user_comment {
  font-size: 12px;
  flex: 2;
  font-family: "Poppins", sans-serif !important;
}

.adminnotification {
  position: fixed;
  width: 100%;
  left: 30%;
  top: 20%;
  z-index: 9990 !important;
}
.Admin_main_box {
  background-color: #ffe7d1;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
}
.admin_content {
  height: 300px;
  padding: 0px 30px 0px 10px;
  overflow-x: hidden;
}
.admin_content::-webkit-scrollbar {
  width: 12px;
}
.title_head {
  font-weight: 700 !important;
  font-size: 20px;
}
.main {
  margin-top: 20;
}

.tonav .titleLogo {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.tonav .titleLogo button {
  color: #ffffff !important;
}

.title {
  font-size: 30 !important;
  font-weight: bold !important;
  color: #000;
  margin-left: 150 !important;
  font-family: "Poppins", sans-serif !important;
}

.badgeStyle {
  color: #fff;
}

.MuiBadge-anchorOriginTopRightRectangle {
  background-color: #fd7e0c;
}

.Menuroot {
  display: none;
}

.menuButton {
  margin-right: "";
}

.hide {
  display: none;
}

.drawer {
  width: auto;
  flex-shrink: 0;
}

.drawerPaper {
  width: auto;
  color: #fff;
  background: #092e5a;
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20;
}

.sidenav {
  width: fit-content;
  margin-top: 150;
  height: 70vh;
}
.whide {
  display: none;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
  min-width: 175px;
  width: 50%;
  color: #fff;
  background-color: #092e5a;
  font-family: "Poppins", sans-serif !important;
}
body {
  padding-right: 0px !important;
  overflow: visible !important;
}

.notification_modal_box .MuiFormControl-marginNormal {
  margin: 10px 0;
  margin-bottom: 20px;
}

.sideBar_avarat_div {
  width: 64px;
  height: 64px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sideBar_avarat_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profileContainer {
  width: 300px;
  height: 250px;
  position: fixed;
  right: 50px;
  top: 78px;
  z-index: 9999 !important;
}
.profile_box {
  margin-bottom: 10px;
}
.profile_picture {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover !important;
  margin-bottom: 10px !important;
  border-radius: 50% !important;
}
.title_Profile {
  padding-top: 5px !important;
  margin-left: 10px !important;
  font-family: "Poppins", sans-serif !important;
}
.title_Profile_box {
  border: 1px solid #092e5a;
  border-radius: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
}
.title_Profile_name_notification {
  font-size: 15px !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0px !important;
  margin: 0px !important;
  word-wrap: break-word !important;
}

.feedcount {
  padding: 2px;
  font-size: 10px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
}

.feed_color_blue {
  color: #fff;
  background-color: #092e5a;
}

.feed_color_orange {
  color: #fff;
  background-color: #fd7e0c;
}

@media only screen and (max-width: 1880px) {
  .adminnotification {
    width: 100%;
    left: 30%;
  }
}
@media only screen and (max-width: 1000px) {
  .adminnotification {
    width: 60%;
    left: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .sidenav {
    display: none !important;
  }
  .pro_Not .profile {
    display: none;
  }
  .notificationContainer .MuiGrid-grid-xs-11 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .create-notification-wrapper {
    max-width: 100% !important;
  }
  .active_admin_feed_notify,
  .admin_feed_notify,
  .create_box {
    font-size: 14px !important;
    padding: 12px 20px !important;
  }
  .mhide {
    display: none;
  }
  .whide {
    display: block;
  }
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    padding: 1px 8px !important;
  }

  .logo {
    display: none !important;
  }

  .blackimg {
    display: none !important;
  }

  .Menuroot {
    display: block !important;
  }

  .title {
    justify-content: center !important;
    font-size: 25px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    color: #fff !important;
  }

  .MuiIconButton-colorInherit {
    color: inherit;
    margin-right: 16px;
  }
  .notificationContainer {
    width: 100%;
    top: 5%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff !important;
    padding-bottom: 50%;
    margin: 0px;
    height: 100%;
  }
  .notification_box {
    background: none;
    box-shadow: none;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
  }
  .notificationModal {
    width: 100%;
    margin: 0px;
    background-color: #fff !important;
    align-items: flex-start;
    padding-top: 80px;
    z-index: 9999 !important;
  }
  .notification_modal_box {
    background: none;
    box-shadow: none;
  }
  .adminnotification {
    width: 100%;
    left: 0%;
    top: 10%;
    background-color: #fff !important;
    padding: 20% 0;
    margin: 0px;
  }
  .notificationModal_success {
    left: 0%;
    top: 10%;
  }
  .cancelicon {
    color: #000;
  }
  .active_admin_feed_notify {
    font-size: 20px;
  }
  .admin_feed_notify {
    font-size: 20px;
  }
  .create_box {
    font-size: 20px;
  }
  .notification_success_box {
    width: 300px !important;
  }
  .success_icon {
    font-size: 100px;
  }
  .create_heading {
    display: none;
  }
  .comment_box {
    height: calc(100vh - 180px);
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .form_notification {
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  .tonav .titleLogo .title {
    margin-left: 0px !important;
    color: #fff !important;
    word-break: break-word;
  }
  .MuiIconButton-colorInherit {
    margin-right: 0px;
  }
  .tonav .main {
    flex-wrap: nowrap;
  }
  .tonav .titleLogo .title {
    font-size: 18px !important;
    letter-spacing: 0;
    word-break: break-word;
  }
}
