.survey_list_wraper {
    height: 600px;
    border-radius: 20px;
    padding: 80px 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.survey_list_two {
    background: #fff;
    border: 1px solid orange;
    margin: 10px 0px;
    padding: 15px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.survey_list_button {
    color: #ffff;
    width: 450px;
    cursor: pointer;
    display: flex;
    padding: 10px 20px;
    font-size: 18px;
    box-sizing: border-box;
    align-items: center;
    font-family: "Poppins", sans-serif;
    border-radius: 5px;
    letter-spacing: .5;
    justify-content: space-evenly;
    background-color: #082E5A;
    margin-top: 50px;
}

.survey_listt_button_text {
    margin-right: 100px;
}

.survey_list_status {
    font-family: Helvetica;
    font-size: 15px;
    color: #082E5A;
    font-weight: 400;
    opacity: 0.5;
    cursor: pointer;
}
.surveyreport .CircularProgressbar {
    width: 260px !important;
}

@media screen and (max-width: 600px) {
    .survey_list_wraper {
        background-color: transparent;
        padding: 15px;
    }

    .survey_list_box {
        word-wrap: break-word;
        width: 55%;
    }

    .survey_list_two {
        width: 100%;
        padding: 10px;
    }

    .survey_list_title_two {
        margin-left: 0px;
    }

    .survey_list_status {
        margin-right: 10px;
    }

    .survey_list_button {
        width: 95%;
        margin-top: 50px;
    }
    .survey_listt_button_text {
        font-size: 12px;
    }
}