.chatInitialImg {
  display: flex;
  margin-top: 26%;
  justify-content: center;
}
.chatInitialImg img {
  width: 250px;
}
.message_name {
  font-weight: 500;
  color: #092e5a;
}
.chatlist-inner.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chatlist-inner .empty-group-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a5a5a5;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.chat-inner-container .chat-bottom-container {
  padding: 20px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-group-btn {
  background-color: transparent;
  border: none;
  color: #212121;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 20px;
}
.mobile-add-group-btn {
  display: none;
}
.new-chat-list {
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 350px);
}

.new-chat-list::-webkit-scrollbar {
  width: 3px;
}

.craete-group-dialog .dialog-inner {
  min-width: 700px;
  max-width: 700px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.craete-group-dialog .dialog-header {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.craete-group-dialog .dialog-content {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.dialog-content.step2 .selected-members-wrapper {
  margin-top: 15px;
}
.dialog-content.step2 .selected-members-wrapper .action-addmore {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}
.selected-members-wrapper .selected-members-title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #797979;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  text-align: left;
  box-sizing: border-box;
}

.craete-group-dialog .dialog-header h2 {
  margin: 0;
  font-size: 25px;
  width: calc(100% - 80px);
  margin-left: 48px;
  text-align: center;
}
.craete-group-dialog .dialog-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}
.dialog-inner .step-select-members {
  width: 100%;
  max-height: 100%;
  display: grid;
  place-items: center;
  margin-top: 10px;
}
.step-select-members .steps-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
}
.steps-wrapper .step {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.steps-wrapper .step span {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #082e5a;
  color: #ffffff;
  border-radius: 50%;
  font-size: 16px;
}
.steps-wrapper .step.active span {
  background-color: #ef7f1a;
}
.steps-wrapper .step label {
  color: #797979;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  margin: 10px 0;
  text-align: center;
}
.steps-wrapper .step:first-child {
  margin-right: 80px;
}
.steps-wrapper .step:first-child span::after {
  content: "";
  width: calc(100% - 120px);
  position: absolute;
  padding: 1px;
  right: 50px;
  border-top: 2px dotted #797979;
}

/**** [Create-Group]: Select Members Section ****/

.cross_icon {
  display: none;
}
.cross_icon_web {
  display: block;
}

.dialog-inner .step-group-info {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.selected-members-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 15px;
  width: 100%;
}
.selected-members-wrapper .selected-member {
  font-size: 12px;
  line-height: 14px;
  color: #212121;
  padding: 4px 8px;
  border: 1px solid #ef8134;
  background-color: #ece9ea;
  border-radius: 12px;
  margin: 5px;
  text-transform: capitalize;
}
.selected-member small {
  margin-left: 10px;
  font-weight: 600;
  cursor: pointer;
}
.member-list {
  display: flex;
  flex-direction: column;
  width: calc(100% - 28px);
  height: 180px;
  overflow: scroll;
}
.member-list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.member-list::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 12px;
}
.member-list .member {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
  box-sizing: border-box;
  width: 180px;
  border-bottom: 2px solid #e6e6e6;
  margin: 10px;
}
.member-list .member .username {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 34px);
  max-height: 20px;
  word-break: break-word;
  box-sizing: border-box;
  padding-left: 8px;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 14px;
  color: #111;
  font-weight: 500;
  cursor: pointer;
}
.member-list .member .action {
  display: none;
}
.member-list .member .username::after {
  content: "";
  max-width: 18px;
  min-width: 18px;
  max-height: 18px;
  min-height: 18px;
  border: 1px solid #ef7f1a;
  border-radius: 50%;
}
.newChatUser {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 34px);
  box-sizing: border-box;
  padding-left: 8px;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 18px;
  color: #111;
  font-weight: 500;
  cursor: pointer;
}
.member-list .member .username::before {
  content: "";
  position: absolute;
  max-width: 12px;
  min-width: 12px;
  max-height: 12px;
  min-height: 12px;
  background-color: #ef7f1a;
  top: 4px;
  right: 4px;
  border-radius: 50%;
  display: none;
}
.member input[type="checkbox"]:checked + .username::before {
  display: block;
}
.step-group-info .right-atoz-filter {
  top: 0;
}
.step-group-info .right-atoz-filter > span {
  margin: 0;
}

.validation-msg {
  font-size: 14px;
  line-height: 16px;
  color: #e56a7f;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  display: inline-flex;
  align-items: center;
}
.validation-msg img {
  margin-right: 8px;
}

.next-button {
  background-color: #082e5a;
  color: #fff;
  font-size: 16px;
  margin: 10px auto;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 14px;
  letter-spacing: 1px;
  width: 35%;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  cursor: pointer;
  text-transform: none;
}
.next-button:hover {
  background-color: #082e5a;
}

/* create group step2 */
.profile-wrapper {
  position: relative;
  display: inline-flex;
  margin-bottom: 20px;
}
.profile-pic {
  width: 200px;
  height: 100%;
  max-height: initial;
  display: inline-block;
  object-fit: cover;
}

.profile-upload {
  opacity: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
}
.profile-upload::-webkit-file-upload-button {
  cursor: pointer;
}
.circle {
  border-radius: 100% !important;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border: 2px solid #de8333;
}
img {
  max-width: 100%;
  height: auto;
}
.p-image {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: -8px;
  z-index: 99;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: saturate(180%) blur(10px);
}
.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.upload-button {
  font-size: 1.2em;
}

.upload-button:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #999;
}

.step-group-info .input {
  height: 50px;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #082e5a;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif !important;
  outline: none;
  color: #212121;
}
.save-button {
  background-color: #082e5a;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 14px;
  letter-spacing: 1px;
  width: 35%;
  height: 50px;
  margin: 25px auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  cursor: pointer;
  text-transform: none;
}
.save-button:hover {
  background-color: #082e5a;
}

.new_sender_msg {
  margin-top: 15px !important;
}

.new_recevier_msg {
  margin-top: 15px !important;
}

.new_message_wrapper {
  margin-top: 5px !important;
}

.attach_image {
  width: 200px;
  height: 200px;
  object-fit: contain !important;
}

.avtar_profile_container {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover !important;
}

.avtar_profile {
   width: 100%;
   height: 100%;
   border-radius: 50%;
   object-fit: cover !important;
}

.register_image{
  border:"2px solid red"
}

@media only screen and (min-width: 768px) {
  .chat-user-box:last-child {
    border-bottom: 0;
  }
  .chat-section .user-chat-section {
    min-height: calc(100vh - 365px);
  }
  .user-chat-section,
  .chat-actions {
    bottom: -30px;
    background: #ffffff;
  }
}

.modal_view {
  height: 500px !important;
  width: 500px;
}
.modal_image {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .right-atoz-filter {
    position: relative;
    top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .chat-inner-container .chat-bottom-container {
    display: none;
  }
  .craete-group-dialog .dialog-inner {
    max-width: 100%;
    min-width: 100%;
    padding: 0;
  }
  .craete-group-dialog .dialog-header {
    padding:13px 20px;
    background-color: #082e5a;
    color: #ffffff;
  }
  .craete-group-dialog .dialog-content {
    padding: 20px;
    padding-top: 10px;
  }
  .mobile-add-group-btn {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 10px 0 0 auto;
    z-index: 9999;
  }
  .MuiDialog-paper.MuiDialog-paperScrollPaper {
    max-height: 100%;
    border-radius: 0;
  }
  .group.user-list {
    display: inline-block !important;
  }
  .tonav {
    display: inline-block !important;
  }
  .css-view-1dbjc4n {
    background-color: #ffffff !important;
  }
  .group.chat-main-container {
    top: 64px;
  }
  .cross-btn {
    display: none;
  }
  .modal_view {
    width: 100%;
    height: 500px !important;
  }
  
}

@media screen and (max-width: 500px) {
  .dialog-inner .step-group-info {
    padding: 0;
    padding-top: 10px;
  }
  .member-list {
    width: calc(100% - 8px);
    height: 435px;
  }
  .member-list .member {
    width: 100%;
  }
  .user-chat-section {
    min-height: 80vh !important;
  }
  .validation-msg {
    font-size: 12px;
  }
  .save-button,
  .next-button {
    width: 100%;
  }
  .cross_icon {
    display: block;
  }
  .cross_icon_web {
    display: none;
  }
  .craete-group-dialog .dialog-header h2 {
    font-size: 21px;
    width: calc(100% - 60px);
    text-align: initial;
    margin-left: 10px;
  }
  .craete-group-dialog .dialog-header {
    padding: 16px 20px;
  }
  .chatlist-inner.empty {
    height: 80vh;
    width: 100%;
  }
  .chat-main-container-new {
    top: 0px;
  }
  .uset-chat-header-group {
    border-radius: 0px !important;
    background-color: #092e5a !important;
    color: #fff !important;
    padding: 20px !important;
  }
  .responsive-chat-section {
    background-color: #092e5a !important;
  }
  .user-chat-section-group {
    padding: 5px 20px;
    background-color: #fff;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    height: 80vh !important;
    overflow-y: scroll;
  }
  .user-chat-section-group::-webkit-scrollbar {
    width: 2px;
  }
  .edit_button {
    background: transparent;
    border: 0px;
    cursor: pointer;
    color: white;
    position: absolute;
    top: 25px;
    right: 10px;
    z-index: 9999;
  }
  .chatUserAvtar {
    width: 15%;
    margin-left: 10%;
  }
  .okay {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 15px;
  }
  .okay .message-wrapper {
    text-align: right;
  }
}

@media only screen and (max-width: 375px) {
  .craete-group-dialog .dialog-header {
    justify-content: flex-start;
  }
  .craete-group-dialog .dialog-header h2 {
    margin-left: 12px;
  }
}