.survey_wraper_one {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.survey_head_text {
  width: 60%;
  margin: 20px 0px;
  text-align: center;
  font-size: 18px;
  font-size: Helvetica;
}

.survey_empty_image {
  width: 30%;
  height: 30%;
}

.button_style {
  margin-top: 50px;
}

.survey_list_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 20px;
  background-color: #feeddb;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
}
.survey_list_container:last-child {
  margin-bottom: 0;
}

.survey_list_image {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.surveyAnalysis-backButton {
  background-color: transparent;
  border: 0;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
.surveyreport.analysis {
  padding-top: 30px !important;
}
.feedbacklist_back_btn {
  background: transparent;
  border: none;
  outline: none;
  padding: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.back-button {
  background: transparent;
  border: none;
  outline: none;
  padding: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.survey_load {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.survey-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.survey-roller div {
  animation: survey-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.survey-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #212121;
  margin: -4px 0 0 -4px;
}
.survey-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.survey-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.survey-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.survey-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.survey-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.survey-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.survey-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.survey-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.survey-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.survey-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.survey-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.survey-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.survey-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.survey-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.survey-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.survey-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes survey-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.survey_list_title {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  word-break: break-word;
}
.survey_wraper_two {
  margin: 10px 0;
}
.bgmain.survey {
  overflow: scroll;
  padding-bottom: 100px !important;
}

@media only screen and (min-width: 768px) {
  .bgmain.survey {
    left: 320px !important;
  }
  .bgmain.survey.eventlist {
    padding: 35px 65px !important;
  }
}

@media only screen and (max-width: 767px) {
  .surveyAnalysis-backButton {
    display: none;
  }
  .back-button {
    display: none;
  }
  .survey_wraper_one.survey-list-empty {
    justify-content: flex-start;
  }
  .survey-list-empty .survey_empty_image {
    width: 50%;
    height: auto;
  }
  .bgmain.survey.eventlist {
    padding: 10px 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .survey_wraper_one {
    height: 60%;
    width: 100%;
    margin-top: 50px;
    justify-content: space-between;
  }
  .bgmain.survey {
    top: 64px;
    margin: 0 !important;
  }
  .survey_head_text {
    font-size: 15px;
    margin: 30px 0px;
    width: 90%;
  }

  .survey_empty_image {
    width: 50%;
    height: 50%;
    margin-top: 10px;
  }

  .survey_wraper_two {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .survey_list_container {
    width: 100%;
    padding: 15px 20px;
  }

  .survey_list_box {
    word-wrap: break-word;
  }

  .survey_list_title {
    font-size: 18px;
  }

  .survey_list {
    width: 100%;
    margin: 10px 20px;
    box-shadow: 21px 16px 36px -4px rgba(0, 0, 0, 0.1);
  }

  .survey_button {
    width: 80%;
    margin-top: 100px;
  }
}
