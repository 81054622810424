.avtar_profile_container {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avtar_profile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.chat-main-container {
  position: absolute;
  top: 110px;
  left: 320px;
  width: calc(100% - 345px);
  background-color: #e7eced;
  padding: 25px;
  border-radius: 16px;
  box-sizing: border-box;
  max-height: calc(100vh - 120px);
}
.chat-inner-container_new_chat {
  background-color: #fff;
  border-radius: 16px;
  height: 75vh;
}

/* userlist section start */
.user-list {
  padding: 20px;
  padding-right: 15px;
  /* overflow-y: auto; */
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 235px);
  border-right: 2px solid #e7e7e7;
}
.user-list::-webkit-scrollbar {
  width: 2px;
}
.user-list::-webkit-scrollbar-thumb {
  background: #e7eced;
}
.user-list .user-list-header {
  /*__header__*/
  text-align: center;
  box-sizing: border-box;
  /* padding-right: 28px; */
}
.user-list .user-list-header span {
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}
.user-list .button-wrapper {
  background-color: #092e5a;
  padding: 3px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
}
.user-list .button-wrapper button {
  color: #fff;
  background: transparent;
  width: 50%;
  border: none;
  border-radius: 8px;
  padding: 8px;
}
.user-list .button-wrapper button.active {
  background-color: #ef8134;
}

.add_image {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.user-list .chat-list {
  /*__chat list section__*/
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 280px);
}
.user-list .chat-list::-webkit-scrollbar {
  width: 12px;
}
.user-list .chatlist-inner {
  width: calc(100% - 30px);
}
.chat-list .chat-user-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #e7e7e7;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.chat-list .more-chat .chat-user-box:last-child {
  border-bottom: 0;
}
.chat-user-box .user-name {
  padding: 0 10px;
  width: calc(100% - 62px);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
}
.chat-user-box .message-count {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #816b4c;
  background-color: #ef8134;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 500;
}
.chat-list .more-chat {
  padding: 15px 0;
}
.chat-list .more-chat .title {
  color: rgba(11, 10, 17, 0.7) !important;
  font-size: 16px !important;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-left: 0 !important;
  margin-bottom: 15px;
  display: inline-block;
}

.right-atoz-filter {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  position: sticky;
  top: 0px;
  margin-left: 10px;
  padding-left: 5px;
  box-sizing: border-box;
  border-left: 1px solid #ef7f1a;
  width: fit-content;
  height: 100%;
}
.right-atoz-filter > span {
  color: #212121;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  line-height: 18px;
}
/* userlist section end */

/* chat-message-section start */
.chat-section {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 170px);
}
/* chat-message-section end */

.image-chat-section {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 170px);
}
.image-chat-section img {
  width: 250px;
}

.craete-group-dialog {
  border-radius: 50px;
}

.chat_user_box {
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 2px solid #e7e7e7;
  cursor: pointer;
}

.chat_user_box span {
  margin-left: 20px;
}

.oye {
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.attach_image {
  width: 200px;
  height: 200px;
  margin-top: 10px;
  object-fit: contain !important;
}
.AddIcon {
  font-size: 12px;
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .chat-user-box img {
    width: 30px;
  }
  .chat-list .chat-user-box {
    padding: 10px 0;
  }
  .user-list .user-list-header {
    padding-right: 0;
  }
  .user-list {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .chat-main-container {
    position: relative;
    width: 100%;
    left: 0;
    top: 64px;
    padding: 0;
    background-color: transparent;
    max-height: max-content;
  }
  .user-list {
    max-width: 100%;
    flex-basis: 100%;
    width: 100%;
    max-height: initial;
    overflow-y: visible;
    padding-top: 0;
  }
  .chat-inner-container_new_chat {
    border-radius: 0px;
    height: 94vh;
  }
  .user-list .user-list-header {
    padding: 0;
    margin-top: 10px;
  }
  .user-list .user-list-header span {
    margin-left: 30px;
    width: calc(100% - 35px);
  }
  .right-atoz-filter {
    top: 90px;
  }
  .right-atoz-filter > span {
    margin: 0;
  }
  .chat-user-box .message-count {
    background-color: #d6d6d6;
    border-color: #aeaeae;
    color: #212121;
  }
  /* .titleLogo {
    width: 100%;
  } */
  .titleLogo .MuiIconButton-colorInherit {
    top: 12px;
  }
  .MuiGrid-root.main.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-space-between {
    background-color: #fff;
  }
  .showNav {
    display: block;
  }
  .hideNav {
    display: none;
  }
  .moveUp {
    top: 0px !important;
  }
  .uset-chat-header-group {
    border-radius: 0px !important;
    background-color: #092e5a !important;
    color: #fff !important;
    padding: 20px !important;
  }
  .user-chat-section {
    min-height: 80vh !important;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
  .attachmentFile {
    width: 72% !important;
  }
  .chat-section {
    background-color: #092e5a !important;
  }
  .user-list .chat-list {
    max-height: calc(100vh - 175px);
  }
  .right-atoz-filter {
    top: 0px;
  }
  .right-atoz-filter > span {
    line-height: 22px;
  }
}
