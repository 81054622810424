@import url('../../blocks/FreeTrial/src/FreeTrial.css');
@import url('../../blocks/events/src/Events.css');
@import url('../../components/assets/css/TopNav.css');
@import url('../../blocks//location/assets/css/GoogleMap.web.css');

::-webkit-scrollbar {
   display: none;
  }

#root {
    box-sizing: border-box;
    margin: 0 auto;
    padding: auto;
    max-width: 1600px;
}

#root .tonav .main {
    max-width: 1600px;    
}

.tonav button.MuiButton-text {
    white-space: nowrap;
}

.rootAbsolute {
    width: 240px !important;
    justify-content: flex-start !important;
    padding-left: 46 !important;
    padding-right: 56 !important;
    position: relative !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
  }

.map-loader {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 1600px) {
    #root .tonav .main {
        left: auto;
        max-width: 1598px;
        transform: translateX(-24px);
        word-break: break-word;
    }
}


@media only screen and (max-width: 767px) {
    .map-loader {
        background: #fff;
    }
}