.MuiGrid-root.main.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-space-between {
  position: fixed !important;
  z-index: 9999 !important;
  background: #fff;
  width: 100%;
  left: 0px;
  padding: 14px 30px;
  margin: 0px !important;
}
.MuiGrid-root.sideNav.MuiGrid-container {
  position: fixed !important;
  width:16.5%;
}
.addeventstyle form input[id*="autocomplete"] {
  width: calc(100% - 30px) !important;
}
.eventb img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
.addeventstyle .MuiInputAdornment-positionStart {
  margin-right: 0px !important;
}
.addeventstyle form .MuiFormControl-fullWidth svg.MuiSvgIcon-root {
  position: absolute;
  right: 10px;
  top: 12px;
  width: 25px;
  height: 25px;
  z-index: 99;
}
.loginlst .con_btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  width: 100% !important;
  max-width: 450px;
  margin: auto;
  background-color: #ed801b;
  border-color: #ed801b;
}

.addeventstyle
  .uploadicon
  span.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary {
  border: 3px solid #e1883e;
  border-radius: 14px !important;
  font-size: 20px !important;
  padding: 5px;
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
}
.addeventstyle
  .uploadicon
  span.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary
  svg {
  font-size: 1.5rem !important;
}
.addeventstyle form .MuiOutlinedInput-adornedEnd:hover input,
.addeventstyle form .MuiOutlinedInput-adornedEnd:active input,
.addeventstyle form .MuiOutlinedInput-adornedEnd:focus input {
  border-color: #778ca4 !important;
}
.avenue .MuiPaper-elevation1 {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%);
  background-color: #fff0;
}
.avenue {
  padding: 40px 90px !important;
}
.tonav .MuiButton-text {
  padding: 12px 38px !important;
}
.loginname {
  padding-left: 15px;
}
.acode h2 {
  font-weight: 600;
  font-size: 19px;
}
.eventb h2 {
  font-size: 16px;
  text-align: center;
  padding-bottom: 60px;
}
.dialogs {
  position: absolute;
  top: 221px;
  padding: 30px 60px;
  right: 380px;
  border-radius: 30px !important;
}
.eventb .con_btn button {
  padding: 8px 15px;
  width: 100% !important;
  justify-content: space-evenly;
}
.protitle {
  font-weight: bold;
  padding: 10px 0px 0px 8px;
  font-size: 18;
}
.eventb {
  padding-top: 150px !important;
}
.loginlst p.log_txt {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20;
}
.loginlst .con_btn button {
  padding: 6px 20px;
  width: 450px;
  background-color: #ed801b;
  border-color: #ed801b;
}
.avenue input#autocomplete {
  width: 100% !important;
  height: 40px;
  border-radius: 6px;
  padding: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.Mui-disabled.Mui-disabled {
  background: #082e5aa6 !important;
}
span.skip {
  float: right;
  margin-right: 180px;
  padding-top: 90px;
  color: #ed801b;
}
/*add event css */

.addeventstyle .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
  font-family: "Poppins", sans-serif;
}
.addeventstyle form {
  padding: 10px 20px 0px 20px;
}
.addeventstyle form .MuiFormControl-fullWidth {
  margin-bottom: 18px;
}
.addeventstyle form p.MuiTypography-body1 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  padding-bottom: 7px;
  text-transform: unset;
  font-weight: 600;
  color: #505050;
}
input::-webkit-date-and-time-value {
  text-align: left !important;
}
.addeventstyle form input {
  border: 3px solid #778ca4 !important;
  border-radius: 8px !important;
  padding: 12px 20px !important;
  background-color: #fff;
  height: 50px;
  box-sizing: border-box;
}
.addeventstyle form input[id*="autocomplete"] {
  border: none !important;
}
.addeventstyle form input[id*="autocomplete"] ~ fieldset {
  border: 3px solid #778ca4 !important;
}
.addeventstyle form fieldset {
  /* border: 3px solid #778ca4 !important; */
  border-radius: 8px !important;
  padding: 12px 20px !important;
}
.addeventstyle .MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
  background-color: #fff;
  border-radius: 8px !important;
}
/* .addeventstyle .MuiInputAdornment-positionStart {
    display: none !IMPORTANT;
} */
.addeventstyle
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background: #082e5a;
  padding: 12px 20px;
}
.formtitle {
  padding: 10px 30px 0px 28px;
}
.ldetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adduserform .MuiOutlinedInput-root {
  position: initial;
  border-radius: 4px;
  border: 3px solid #778ca4;
}
.adduserform .MuiTypography-body1 {
  margin: 8px 1px;
}
.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel svg {
  font-size: 44px !important;
  margin-top: -10px;
  border: 1px solid #f70;
  border-radius: 25px;
  color: #fff;
}
.MuiStepLabel-label.MuiStepLabel-active {
  color: #082e5a !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-family: "Poppins", sans-serif !important;
}
span.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  border-color: #ff7700;
  border-top-style: dotted;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #082e5a !important;
  border-color: #082e5a !important;
  background-color: #082e5a !important;
}
text.MuiStepIcon-text {
  fill: #f70;
}
.MuiStepIcon-root.MuiStepIcon-active text.MuiStepIcon-text {
  fill: #ffeddc;
}
@media only screen and (max-width: 767px) {
  .addeventstyle {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    padding: 20px 12px !important;
    padding-left: 25px !important;
  }
  .avenue input#autocomplete {
    margin-top: 35px !important;
  }
  .MuiGrid-root.main.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-space-between {
    position: fixed !important;
    z-index: 9999 !important;
    background: #082e5a;
    width: 100%;
    left: 0px;
    padding: 5px 20px;
    margin: 0px !important;
    top: 0px !important;
  }
  .MuiGrid-root.bgmain.addeventstyle.MuiGrid-item.MuiGrid-grid-xs-7.MuiGrid-grid-sm-7.MuiGrid-grid-lg-8 {
    margin-top: 70px !important;
  }
  .dialogs {
    position: absolute;
    top: 160px;
    padding: 30px 60px;
    right: 0px;
    border-radius: 30px !important;
  }
  .MuiContainer-root.tonav.MuiContainer-maxWidthXl {
    background-color: #082e5a !important;
  }
  /* .MuiGrid-root.mobbackcol.MuiGrid-container.MuiGrid-spacing-xs-3 {
    background-color: #082e5a;
} */
  .eventb h2 {
    padding-bottom: 20px !important;
  }
  horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-active {
    width: 58% !important;
  }
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
    background-color: #ffffff;
  }
  input.pac-target-input {
    width: calc(100% - 30px) !important;
    top: 40 !important;
  }
  span.skip {
    display: none;
  }
  .con_btn {
    margin-top: 100px;
  }
  .MuiPaper-root.adduserform.MuiPaper-elevation1.MuiPaper-rounded {
    width: 100% !important;
    max-width: 280px;
    padding: 60px 70px !important;
  }
  /* .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
    top: 25% !important;
    width: 75% !important;
    padding: 70px 50px !important;
    border-radius: 7% !important;
} */
  .MuiPaper-root.success.MuiPaper-elevation1.MuiPaper-rounded {
    width: 95% !important;
    height: 300px !important;
  }
  .acode h2 {
    padding: 0;
  }
  .generatecode .MuiPaper-elevation1 {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%),
      0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
  }
  button.MuiButtonBase-root.MuiIconButton-root.addpro.MuiIconButton-colorPrimary {
    border: 1px solid;
    border-radius: 5px;
    width: 98%;
    margin: 0 5px;
    margin-top: 15px;
    box-sizing: border-box;
  }
  button.MuiButtonBase-root.MuiIconButton-root.addpro.MuiIconButton-colorPrimary
    span.MuiIconButton-label {
    justify-content: flex-start;
    padding: 0 15px;
    font-weight: normal;
    color: #7e7e7e;
  }
  .loginlst .con_btn button {
    max-width: 150px;
  }
  .loginlst p {
    display: none;
  }
  .loginlst .con_btn button img {
    margin-left: 5px !important;
  }
  .acode p {
    padding: 0;
    font-size: 14px;
  }
  .protitle {
    padding: 10px 30px 10px 8px;
  }
  .ldetails {
    display: block;
    position: relative;
  }
  .loginphone {
    text-align: right !important;
    margin-top: -24px;
  }
  .logindelete {
    position: absolute;
    right: 4px;
    top: 20px;
  }
  .MuiGrid-root.bgmain.loglist.addeventstyle.MuiGrid-item.MuiGrid-grid-xs-7.MuiGrid-grid-sm-7.MuiGrid-grid-lg-7 {
    padding-left: 13px !important;
  }
  .logindate {
    margin-left: 45px;
    margin-top: 8px;
  }
  .loginname {
    margin-left: 0px;
    padding-left: 8px;
  }
  .addeventstyle form {
    padding: 0px !important;
  }
  .addeventstyle form .MuiFormControl-fullWidth {
    margin-bottom: 0px;
  }
  .addeventstyle form .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    font-family: "Poppins", sans-serif;
    margin-bottom: 2px;
  }

  .addeventstyle form p.MuiTypography-body1 {
    font-weight: 500;
    padding-left: 8px;
  }
  .avenue {
    padding: 15px 34px !important;
  }
  .addeventstyle .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
    color: #082e5a;
    text-align: left !important;
  }
  .addeventstyle .formtitle {
    padding: 0px;
    margin-bottom: 20px !important;
    margin-top: 20px;
    margin-left: 10px;
    font-weight: 600 !important;
  }
  .addeventstyle
    .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
    padding: 15px 0px !important;
    align-items: center;
    justify-content: space-between !important;
  }
  /* .addeventstyle button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {

} */
  .generatecode {
    padding: 0px !important;
  }

  /* .loglist button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    width: 65% !important;
    float: right !IMPORTANT;
    margin-bottom: 20px !IMPORTANT;
    background: rgb(239, 127, 26) !important;
}*/
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.buttondone.MuiButton-containedPrimary {
    width: 100% !important;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.auser.MuiButton-containedPrimary {
    background: rgb(239, 127, 26) !important;
    color: white;
    width: 54% !important;
    /* margin: auto; */
    border-radius: 8px;
    margin-left: 150px !important;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.textuser.MuiButton-containedPrimary {
    margin-left: 0px !important;
  }

  .loglist span.MuiButton-label img {
    margin-left: 16px !important;
  }
  .loglist .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
    background: #ffeddc;
    margin: 19px 0px !important;
    box-shadow: 0px 0px 10px 2px #fffaf5;
  }
}
span.MuiButtonBase-root.MuiIconButton-root.addpro.MuiIconButton-colorPrimary {
  font-size: 16px;
  font-weight: 600;
}
.edit-program-venue {
  background-color: #e7eaef;
  border: none;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 5px;
  cursor: pointer;
}
.pro-timedate-title {
  padding: 10px 0px 0px 30px;
  color: #505050;
  font-weight: bold;
  font-size: 18px;
}
.multiple-program-head {
  color: #505050;
  font-weight: bold;
  font-size: 18px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../assets/celender-input-icon.png") no-repeat;
  cursor: pointer;
}
form input[type="date"] {
  background: url("../assets/celender-input-icon.png") no-repeat;
  background-size: 18px auto;
  background-position: calc(100% - 22px) center;
  padding-right: 20px !important;
  background-color: #fff;
  /* cursor: pointer; */
}
form input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../assets/time_input_icon.png") no-repeat;
  cursor: pointer;
}
form input[type="time"] {
  background: url("../assets/time_input_icon.png") no-repeat;
  background-size: 18px auto;
  background-position: calc(100% - 22px) center;
  padding-right: 20px !important;
  background-color: #fff;
  cursor: pointer;
  /* cursor: pointer; */
}

.back-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.addprograms-next-btn {
  background: #082e5a !important;
  color: #fff !important;
  width: 70% !important;
  margin-top: 15px !important;
  text-transform: none !important;
  padding: 10px !important;
}
.addevent-next-btn {
  color: white !important;
  width: 70% !important;
  margin-top: 25px !important;
  text-transform: none !important;
}
@media only screen and (min-width: 767px) {
  .tonav .titlelogo .title {
    margin-left: 165px !important;
    word-break: break-word;
  }
  .bgmain.addeventstyle,
  .bgmain.avenue {
    left: 320px !important;
  }
}
@media only screen and (max-width: 767px) {
  .edit-program-venue {
    position: relative;
    left: -5px;
    /* top: 0; */
    background: transparent;
  }
  .pro-timedate-title {
    padding-left: 10px;
    color: #082e5a !important;
  }
  .multiple-program-head {
    color: #082e5a;
  }
  .back-btn {
    display: none;
  }
  .addprograms-next-btn {
    width: 98% !important;
    margin-top: 25px !important;
  }
  .button.MuiButtonBase-root.MuiIconButton-root.addpro.MuiIconButton-colorPrimary {
    justify-content: flex-start;
  }
  button.MuiButtonBase-root.MuiIconButton-root.addpro.MuiIconButton-colorPrimary
    .add-more-program {
    text-decoration: none !important;
  }
  .addevent-next-btn {
    width: 98% !important;
  }
}
@media only screen and (max-width: 480px) {
  .addeventstyle form input {
    padding: 12px 15px !important;
  }
}
.MuiStepConnector-alternativeLabel {
  top: 12px;
  left: calc(-50% + 25px) !important;
  right: calc(50% + 25px) !important;
  position: absolute;
}
